<script lang="ts">
  import { createCollapsible, melt } from "@melt-ui/svelte";
  import { MinusIcon, PlusIcon } from "lucide-svelte";
  import { slide } from "svelte/transition";

  export let headline: string;
  export let icon: any | undefined = undefined;

  let {
    elements: { root, content, trigger },
    states: { open },
  } = createCollapsible();
</script>

<div use:melt={$root}>
  <hr class="border-light-green/30 marketing-contained-negate-ml" />
  <div class="py-5">
    <button use:melt={$trigger} class="flex w-full gap-5 items-start">
      {#if icon}
        <svelte:component this={icon} class="text-brand-green shrink-0" />
      {/if}
      <p class="marketing-h4 pb-1 text-left">{headline}</p>
      {#if $open}
        <MinusIcon class="ml-auto shrink-0" />
      {:else}
        <PlusIcon class="ml-auto shrink-0" />
      {/if}
    </button>
    {#if $open}
      <div
        use:melt={$content}
        transition:slide|local
        class="pt-3 text-base text-light-green/60"
      >
        <slot />
      </div>
    {/if}
  </div>
</div>
